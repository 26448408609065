<template>
    <div class="component-wrap">

        <AppContentHeader headline="Design Orders">
            <AppInput
                    class="filter-orders__field"
                    required
                    placeholder="Filter by any option"
                    v-model="filter"
            />
        </AppContentHeader>

      <md-dialog :md-active.sync="showDialog">
        <md-dialog-title>Note</md-dialog-title>

        <md-dialog-content>

          <b>Design note</b>
          <p>{{noteMessage}}</p>
          <div v-if="referenceField === ''">
          <b>Reference field</b>
          <p>{{referenceField}}</p>
          </div>
          <div v-if="uploadDescription !== null">
          <b>Upload Description</b>
          <p>{{uploadDescription}}</p>
          </div>

        </md-dialog-content>

        <md-dialog-actions>
          <md-button class="md-primary" @click="showDialog = false">Close</md-button>
        </md-dialog-actions>
      </md-dialog>

        <AppSpinner v-if="loadingSpinner" :loading="loadingSpinner"/>

        <transition mode="out-in" name="fade">

            <md-tabs class="design-orders-tabs" v-show="!loadingSpinner">

                <md-tab id="tab-sponsorships" md-label="Sponsorships" @click="clickCurrentTab(1)">
                    <div class="md-layout md-alignment-top-center">

<!--                        <OrderCard-->
<!--                                v-for="order in filteredSponsorships"-->
<!--                                :key="order.sponsorships_id + '_sponsorships'"-->
<!--                                :orderData="order"-->
<!--                                class="md-layout-item md-xlarge-size-30 md-large-size-30 md-medium-size-30 md-small-size-50 md-xsmall-size-100">-->
<!--                        </OrderCard>-->

                      <div style="width: 100%; height: 70vh; overflow-y: auto;">
                        <table style="width: 100%; border-collapse: collapse;">
                          <thead>
                          <tr style="position: sticky; top: 0; background-color: white; z-index: 2;">
                            <th style="padding: 10px;">ID</th>
                            <th style="padding: 10px;">Thumbnail</th>
                            <th style="padding: 10px;">Status</th>
                            <th style="padding: 10px;">Adspaces</th>
                            <th style="padding: 10px;">End market disposition</th>
                            <th style="padding: 10px;">Download</th>
                            <th style="padding: 10px;">Change Status</th>
                            <th style="padding: 10px;">Note</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="order in filteredSponsorships" :key="order.sponsorships_id + '_sponsorships'">
                            <td style="padding: 10px;">{{ order.sponsorships_id }}</td>
                            <td style="padding: 10px;">
                              <img @click="GoToOrder(order.sponsorships_id)"
                                   v-if="!order.artwork_url_thumbnail"
                                   style="width: 200px; cursor: pointer;"
                                   :src="fallbackImage"
                                   alt="Fallback Image">
                              <img @click="GoToOrder(order.sponsorships_id)"
                                   v-else
                                   style="width: 200px; cursor: pointer;"
                                   :src="order.artwork_url_thumbnail"
                                   alt="Order Image">
                            </td>
                            <td style="padding: 10px;">{{ order.sponsorship_status_name }}</td>
                            <td style="padding: 10px;">{{ order.adspaces_description }}</td>
                            <td style="padding: 10px;">{{ order.end_market_disposition }}</td>
                            <td style="padding: 10px;">
                              <md-button class="md-raised md-primary"
                                         :href="order.original_url"
                                         target="_blank">Download</md-button>
                            </td>
                            <td style="padding: 10px;">
                              <div>
                                <OrderCardStatusesMenu :order="order" @statusUpdated="onStatusUpdated(1)" />
                              </div>
                            </td>
                            <td style="padding: 10px;">
                              <md-button class="md-raised md-primary"
                                         @click.stop.prevent="OpenNote(1, order)">Note</md-button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>


                    </div>

                    <div v-if="fetchingData" class="spinner"></div>
                    <AppEmptyState message="There is no sponsorships to display." v-else-if="!fetchingData && !sponsorships.length && !dontShowMsg"/>

                </md-tab>

                <md-tab id="tab-free-standing" md-label="Free Standing" @click="clickCurrentTab(2)">
                    <div class="md-layout md-alignment-top-center">

                        <OrderCard
                                v-for="(order, index) in freeStanding"
                                :key="index + '_freeStanding'"
                                class="md-layout-item md-xlarge-size-30 md-large-size-30 md-medium-size-30 md-small-size-50 md-xsmall-size-100">
                        </OrderCard>

                    </div>


                    <AppEmptyState message="There is no free standing orders to display." v-if="!freeStanding.length && !dontShowMsg"/>

                </md-tab>

                <md-tab id="tab-signage" md-label="Signage"  @click="clickCurrentTab(3)">

                    <div class="md-layout md-alignment-top-center">

<!--                        <OrderCard-->
<!--                                v-for="order in filteredSignage"-->
<!--                                :key="order.id + '_signage'"-->
<!--                                :orderData="order"-->
<!--                                class="md-layout-item md-xlarge-size-30 md-large-size-30 md-medium-size-30 md-small-size-50 md-xsmall-size-100">-->
<!--                        </OrderCard>-->

                      <div style="width: 100%; height: 70vh; overflow-y: auto;">
                        <table style="width: 100%; border-collapse: collapse;">
                          <thead>
                          <tr style="position: sticky; top: 0; background-color: white; z-index: 2;">
                            <th style="padding: 10px;">ID</th>
                            <th style="padding: 10px;">Thumbnail</th>
                            <th style="padding: 10px;">Status</th>
                            <th style="padding: 10px;">Adspaces</th>
                            <th style="padding: 10px;">End market disposition</th>
                            <th style="padding: 10px;">Download</th>
                            <th style="padding: 10px;">Change Status</th>
                            <th style="padding: 10px;">Note</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="order in filteredSignage" :key="order.id + '_signage'">
                            <td style="padding: 10px;">{{ order.sponsorships_id }}</td>
                            <td style="padding: 10px;">
                              <img @click="GoToOrder2(order.id)"
                                   v-if="!order.upload_thumbnail_url"
                                   style="width: 200px; cursor: pointer;"
                                   :src="fallbackImage"
                                   alt="Fallback Image">
                              <img @click="GoToOrder2(order.id)"
                                   v-else
                                   style="width: 200px; cursor: pointer;"
                                   :src="order.upload_thumbnail_url"
                                   alt="Order Image">
                            </td>
                            <td style="padding: 10px;">{{ order.status_name }}</td>
                            <td style="padding: 10px;">{{ order.signage_description }}</td>
                            <td style="padding: 10px;">{{ order.end_market_disposition }}</td>
                            <td style="padding: 10px;">
                              <md-button class="md-raised md-primary"
                                         :href="order.original_url"
                                         target="_blank">Download</md-button>
                            </td>
                            <td style="padding: 10px;">
                              <div>
                                <OrderCardStatusesMenu :order="order" @statusUpdated="onStatusUpdated(3)" />
                              </div>
                            </td>
                            <td style="padding: 10px;">
                              <md-button class="md-raised md-primary"
                                         @click.stop.prevent="OpenNote(2, order)">Note</md-button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>


                    </div>

                    <div v-if="fetchingData" class="spinner"></div>
                    <AppEmptyState message="There is no free signage to display." v-else-if="!fetchingData && !signage.length && !dontShowMsg"/>

                </md-tab>

            </md-tabs>
        </transition>

        <md-snackbar
                md-position="center"
                :md-active.sync="snackbar.show"
                :md-duration="snackbar.duration"
                md-persistent>

            <span>{{snackbar.message}}</span>
            <md-button class="md-primary" @click="snackbar.show = false">Got it</md-button>

        </md-snackbar>
        <!-- snackbar to show response message -->

    </div>
</template>

<script>
import OrderCard from "@/components/Common/OrderCard"
import {DeepFilterMixin} from "@/mixins/DeepFilterMixin"
import Cookie from 'js-cookie'
import OrderCardStatusesMenu from "@/components/Common/OrderCardStatusesMenu.vue";


export default {
    name: 'DesignDashboard',
    mixins: [DeepFilterMixin],
  props: {
    cardMode: {
      type: String,
      default: 'design',
    },
    orderData: {
      type: Object,
      default: null,
    },
    nextStatuses: {
      type: Object,
      default: null,
    },
  },
    data: function() {
        return {
            fallbackImage: document.location.origin + require('@/assets/img/empty_state_image.png'),
            dontShowMsg: false,
            sponsorships: [],
            freeStanding: [],
            signage: [],
            tabNumber: 1,
            loadingSpinner: false,
            snackbar: {
                show: false,
                message: '',
                duration: 4000
            },
            filter: '',
          fetchingData: true,
          showDialog: false,
          noteMessage: null,
          referenceField: "",
          uploadDescription: null
        }
    },
    components: {
      OrderCardStatusesMenu,
        OrderCard
    },
    created() {
        
        //EventBus.$on('data-saved', this.loadData(1));
        setTimeout(() => {
        this.loadData(1);
        }, 1000);
        this.getMaterials();
        this.filter = this.$store.getters['getSignageFilter'];
    },

    beforeRouteLeave(to, from, next) {
        this.$store.dispatch('saveSignageFilter', this.filter);
        next();
    },
    computed: {
        filteredSponsorships() {
            return this.sponsorships.filter(x => this.filterFunction(x, this.filter));
        },
        filteredSignage() {
            return this.signage.filter(x => this.filterFunction(x, this.filter));
        }
    },
    methods: {
      OpenNote(type, note){
        if(type === 1) {
          this.noteMessage = note.sponsorships_install_notes !== "" ? note.sponsorships_install_notes : "No message"
          this.referenceField = null;
          this.uploadDescription = null;
        }
        if(type === 2) {
          this.noteMessage = note.notes !== "" ? note.notes : "No message"
          this.referenceField = note.reference_field !== "" ? note.reference_field : null;
          this.uploadDescription = note.upload_description !== "" ? note.upload_description : null
        }
        this.showDialog = true;
      },
      onStatusUpdated(tabID) {
        this.loadData(tabID);
        this.$emit('statusUpdated');
      },
      GoToOrder(id){
        this.$router.push(`/design/sponsorship/${id}`);
      },
      GoToOrder2(id){
        this.$router.push(`/design/signage/${id}`);
      },
        clickCurrentTab(tabNumber) {
            this.dontShowMsg = true;
            this.loadData(tabNumber);
        },
        showSnackbar(message) {
            this.snackbar.message = message;
            this.snackbar.show = true;
        },

        async loadData(tabNumber) {

            this.dontShowMsg = true;
            this.fetchingData = true;

            try {
                if (tabNumber === 1) {

                    this.sponsorships = (await this.$axios.get(`sponsorships?season_id=${Cookie.get('season_id')}`)).data;
                    this.dontShowMsg = false;

                } else if (tabNumber === 2) {

                    // this.freeStanding = (await this.$axios.get('free-standing')).data;
                    this.freeStanding = [];
                    this.dontShowMsg = false;

                } else {

                     this.signage = (await this.$axios.get(`signage-orders?season_id=${Cookie.get('season_id')}`, { params: { current_season: true } })).data;
                    this.dontShowMsg = false;
                }

                this.loadingSpinner = false;
                this.fetchingData = false;

            } catch (err) {
                this.loadingSpinner = false;
                this.fetchingData = false;
                // this.showSnackbar(err.message);
            }
        },

        getMaterials() {
            this.$store.dispatch('materials/getMaterials');
        }
    }
}
</script>

<style lang="scss" scoped>

    .design-orders-tabs {
        border: 1px solid rgba($color-black, .4);

        /deep/ .md-tabs-navigation {
            @include respond(xsmall) {
                flex-direction: column;
            }

            .md-button {
                @include respond(small) {
                    width: 100%;
                    max-width: 100%;
                }
             }
        }
    }

    .spinner {
      margin: 0 auto;
      border: 4px solid rgba(0, 0, 0, 0.1);
      border-left-color: #cd0a2f;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
</style>